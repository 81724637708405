import { LANGUAGE } from '@share/constant';
import { LanguageType } from '@share/enums';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import i18n from 'i18next';
import type { ChainedBackendOptions } from 'i18next-chained-backend';
import type { InitOptions } from 'i18next';

interface LanguageItem {
  key: LanguageType;
  name: string;
}

export const LanguageList: LanguageItem[] = [
  {
    key: LanguageType.EN,
    name: 'English',
  },
  {
    key: LanguageType.ZH_TW,
    name: '繁體中文',
  },
  {
    key: LanguageType.ZH_CN,
    name: '简体中文',
  },
];

export const I18nConfig: InitOptions & ChainedBackendOptions = {
  lng: JSON.parse(
    localStorage.getItem(LANGUAGE) ||
      `"${process.env.REACT_APP_DEFAULT_LANGUAGE || LanguageType.EN}"`
  ),
  fallbackLng: process.env.REACT_APP_DEFAULT_LANGUAGE || LanguageType.EN,
  supportedLngs: LanguageList.map((item) => item.key),
  returnNull: false,
  interpolation: {
    escapeValue: false, // No needed for React as it escapes by default
  },
  react: {
    // Since we use backend to load translations, we need to suspense the UI until load it
    useSuspense: true,
  },
  backend: {
    backends: [
      LocalStorageBackend, // Primary
      HttpApi, // Fallback
    ],
    backendOptions: [
      {
        /* Options for primary backend */
        defaultVersion: __GIT_COMMIT__,
        expirationTime:
          process.env.NODE_ENV === 'development' ? 0 : 7 * 24 * 60 * 60 * 1000,
      },
      {
        /* Options for secondary backend */
      },
    ],
  },
};

i18n.use(initReactI18next).use(Backend).init(I18nConfig);

export default i18n;
