export * from './api-error-status';

export enum PaymentStatus {
  NEED_AUTHENTICATION = 'NEED_AUTHENTICATION',
  FAILED = 'FAILED',

  // Checkout.com Status
  PENDING = 'Pending',
  AUTHORIZED = 'Authorized',
  VERIFIED = 'Card Verified',
  VOIDED = 'Voided',
  PARTIALLY_CAPTURED = 'Partially Captured',
  CAPTURED = 'Captured',
  PARTIALLY_REFUNDED = 'Partially Refunded',
  REFUNDED = 'Refunded',
  DELINED = 'Declined',
  CANCELED = 'Canceled',
  EXPIRED = 'Expired',
  PAID = 'Paid',
}

export enum BalanceType {
  RECEIVE = 'Receive',
  WITHDRAW = 'Withdraw',
  DEPOSIT = 'Deposit',
  FREEZE = 'Freeze',
  MINT = 'Mint',
  ROYALTY = 'Royalty',
  SERVICE_FEE = 'Service Fee',
}

export const enum ThemeType {
  LIGHT = 'light',
  DARK = 'is_dark',
}

export const enum LanguageType {
  EN = 'en',
  ZH_TW = 'zh-TW',
  ZH_CN = 'zh-CN',
}

export const enum LaunchpadStatus {
  PUBLISH = 'PUBLISH',
  ENDED = 'ENDED',
}

export const enum ContractType {
  MARKETPLACE_721 = 'MARKETPLACE_721',
  COLLECTION_SIGN_CONTRACT_INFO = 'COLLECTION_SIGN_CONTRACT_INFO',
  COLLECTION_CONTRACT_FACTORY = 'COLLECTION_CONTRACT_FACTORY',
  MARKETPLACE_TREASURY_WALLET_CONTRACT = 'MARKETPLACE_TREASURY_WALLET_CONTRACT',
}

export const enum CollectionStatus {
  PENDING = 'PENDING',
  LISTING = 'LISTING',
  HIDDEN = 'HIDDEN',
  BLOCKED = 'BLOCKED',
}

export const enum TokenType {
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155',
  SOULBOUND = 'SOULBOUND',
}

export const enum PaymentEventType {
  DEPLOY_COLLECTION = 'DEPLOY_COLLECTION',
  MINT_NFT = 'MINT_NFT',
  BUY_NFT = 'BUY_NFT',
  BID_NFT = 'BID_NFT',
}

export const enum NftEventType {
  COUNT = 'COUNT',
  COUNTING_AND_GROUPING = 'COUNTING_AND_GROUPING',
  DISPLAY = 'DISPLAY',
}

export const enum PaymentGatewayType {
  CREDIT_CARD = 'CREDIT_CARD',
  CRYPTOCURRENCY = 'CRYPTOCURRENCY',
  INTEGRATION_PAYMENT = 'INTEGRATION_PAYMENT',
}

export const enum PaymentGatewayName {
  CHECKOUT = 'CHECKOUT',
  GKASH = 'GKASH',
  PAYDOLLAR = 'PAYDOLLAR',
  STRIPE = 'STRIPE',
  METAMASK = 'METAMASK',
}

export const enum KycStatus {
  APPROVE = 'APPROVE',
  PENDING = 'PENDING',
}

export const enum AssetType {
  IMAGE = 'IMAGE',
  MODEL = 'MODEL',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
}

export const enum OrderType {
  BID = 'BID',
  SALE = 'SALE',
}

export const enum EventType {
  SALE = 'SALE',
  LIST = 'LIST',
  TRANSFER = 'TRANSFER',
  MINTED = 'MINTED',
  BID_ACCEPT = 'BID_ACCEPT',
  BID = 'BID',
  CANCEL = 'CANCEL',
}

export const enum ListingType {
  BID = 'bid',
  BUY = 'buy',
}

export const enum ModalName {
  BID = 'bid-modal',
  BUY = 'buy-modal',
  CANCEL = 'cancel-modal',
  CREATE_BID = 'create-bid-modal',
  CREATE_BUY = 'create-buy-modal',
  SELECT_PAYMENT = 'select-payment-modal',
  CHECKOUT = 'checkout-modal',
  SETTLE = 'settle-modal',
  TAKE_HIGHEST = 'take-highest-modal',
  WITHDRAW = 'withdraw-modal',
  SET_AVATAR = 'set-avatar-modal',
  SHOW_RELATION = 'show-relation-modal',
  CROP_IMAGE = 'crop-image-modal',
  MINT = 'mint-modal',
  FLEXIBLE_MINT_FORM = 'flexible-mint-form-modal',
  SHOW_SOURCE = 'show-source-modal',
  TEMPLATE = 'template-modal',
  REGISTER = 'register-modal',
  TFA_RECOVERY_CODES = 'tfa-recovery-codes-modal',
  TFA_RETRIEVE = 'tfa-retrieve',
  TWO_FA = 'two-fa-modal',
}

export const enum AttributeDisplayType {
  COUNT_DOWN = 'count_down',
  DATE_TIME = 'date_time',
  URL = 'url',
}

export const enum FlexibleMintSubmitStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING', // Backend processing
  MINTING = 'MINTING', // Web3 processing
  CANCEL = 'CANCEL',
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  FAILED = 'FAILED', // Web3 failed
  DONE = 'DONE',
}

export const enum WalletProviderType {
  CUSTONOMY = 'CUSTONOMY',
  NFTIV = 'NFTIV',
}
