/* Autogenerated file. Do not edit manually. */

export enum APIErrorStatus {
  /** User Error 01 */
  USER_NOT_FOUND = 1001,
  USER_NOT_KYC = 1002,
  USER_LOGIN_INFO_ERROR = 1003,
  KYC_HAS_SUBMITTED = 1004,
  KYC_RECORD_NOT_FOUND = 1005,
  EMAIL_NOT_FOUND = 1006,
  EMAIL_ALREADY_EXISTS = 1007,
  EMAIL_LINK_INVALID = 1008,
  EMAIL_CODE_NOT_MATCH = 1009,
  EMAIL_NOT_ALLOW = 1010,
  USER_NOT_ACCOUNT_KYC = 1011,
  USER_NOT_ID_KYC = 1012,
  USER_NOT_PHONE_KYC = 1013,
  USER_NOT_FACE_KYC = 1014,
  USER_NOT_BANK_KYC = 1015,
  EMAIL_ALREADY_SENT = 1016,
  EMAIL_ALREADY_VERIFIED = 1017,
  EMAIL_INVALID = 1018,
  ACCOUNT_BLOCKED = 1019,
  PHONE_OTP_RESEND_COLD_DOWN = 1020,
  PHONE_OTP_NOT_FOUND = 1021,
  PHONE_OTP_INVALID = 1022,
  PHONE_ALREADY_EXISTS = 1023,
  PHONE_NOT_CANCEL_BIND = 1024,
  EMAIL_NOT_CANCEL_BIND = 1025,
  USER_NOT_SELF = 1026,
  USER_NOT_FOUND_TIPS = 1027,
  TWOFA_OTP_INVALID = 1028,
  TWOFA_SECRET_NOT_BIND = 1029,
  TWOFA_TIME_OUT = 1030,
  TWOFA_BOUND = 1031,
  TWOFA_RECOVERY_INVALID = 1032,

  /** Network Error 02 */
  NETWORK_NOT_FOUND = 2001,

  /** Collection Error 03 */
  COLLECTION_NOT_FOUND = 3001,
  COLLECTION_ADDRESS_INVALID = 3002,
  COLLECTION_NOT_LAUNCHPAD = 3003,
  COLLECTION_CONTRACT_DATA_ERROR = 3004,
  COLLECTION_OWNER_INVALID = 3005,
  PARENT_COLLECTION_NOT_FOUND = 3006,

  /** NFT Error 04 */
  NFT_NOT_FOUND = 4001,
  NFT_TOKEN_ID_INVALID = 4002,
  NFT_OWNER_INVALID = 4003,
  NFT_TYPE_NOT_SUPPORTED = 4004,
  NFT_SOULBOUND_NOT_SUPPORT = 4005,
  NFT_OCCUPIED = 4006,
  NFT_OWNER_NOT_FOUND = 4007,
  NFT_ACTIVITY_TRANSACTION_HASH_EXIST = 4008,
  NFT_ACTIVITY_NOT_FOUND = 4009,
  NFT_IS_ON_SALE = 4010,
  NFT_OWNER_NOT_ENOUGH_QUANTITY = 4011,
  NFT_ACTION_EXISTS = 4012,
  NFT_MINTED = 4013,
  NFT_ACTIVITY_TYPE_NOT_FOUND = 4014,
  NFT_OWNED = 4015,
  NFT_METADATA_INVALID = 4016,

  /** Wallet Error 05 */
  WALLET_NOT_FOUND = 5001,
  WALLET_ADDRESS_INVALID = 5002,
  WALLET_OCCUPIED_BY_LISTING = 5003,
  WALLET_IS_NOT_TREASURY = 5004,
  WALLET_NONCE_NOT_FOUND = 5005,
  WALLET_SIGNATURE_INVALID = 5006,
  WALLET_ALREADY_CONNECTED = 5007,
  WALLET_IS_TREASURY = 5008,
  WALLET_IS_LAST = 5009,
  WALLET_NOT_DISCONNECT = 5010,
  WALLET_IS_DEFAULT = 5011,
  TREASURY_ADDRESS_NOT_EXIST = 5012,

  /** Marketplace Error 06 */
  WITHDRAW_NFT_PROCESSING = 6001,
  WITHDRAW_NFT_PENDING = 6002,
  ORDER_NOT_FOUND = 6003,
  ORDER_NOT_PERMISSION = 6004,
  ORDER_NOT_BUYER = 6005,
  ORDER_NOT_SELLER = 6006,
  ORDER_EXPIRED = 6007,
  ORDER_NOT_ACTIVE = 6008,
  ORDER_NOT_CANCELABLE = 6009,
  ORDER_NOT_CANCELLED = 6010,
  ORDER_EXIST = 6011,
  ORDER_ACTION_END_DATE_IS_PAST = 6012,
  ORDER_BID_NOT_FOUND = 6013,
  ORDER_BID_PRICE_ERROR = 6014,
  ORDER_PRICE_ERROR = 6015,
  ORDER_PRICE_NOT_MATCH = 6016,
  ORDER_SELLER_NOT_MATCH = 6017,
  ORDER_BID_ACCEPT_PENDING = 6018,
  ORDER_ACTION_END_DATE_INVALID = 6019,

  /** Web3 Service Error 07 */
  WEB3_SERVICE_ERROR = 7001,
  WEB3_BACKEND_ERROR = 7002,

  /** Currency Error 08 */
  CURRENCY_NOT_FOUND = 8001,
  CURRENCY_NOT_SUPPORT = 8002,

  /** Payment Error 09 */
  CREDIT_CARD_NOT_3DS = 9001,
  PAYMENT_NOT_FOUND = 9002,
  PAYMENT_GATEWAY_NOT_SUPPORT = 9003,
  PAYMENT_DECLINED = 9004,
  PAYMENT_REQUEST_EXPIRED = 9005,

  /** Event Error 10 */
  EVENT_NOT_FOUND = 10001,
  EVENT_NOT_REDEEMABLE = 10002,
  EVENT_NOT_PERMISSION = 10003,
  EVENT_REDEEM_AMOUNT_WRONG = 10004,

  /** Launchpad Error 11 */
  LAUNCHPAD_NOT_FOUND = 11001,
  LAUNCHPAD_PHASE_NOT_MATCH = 11002,
  LAUNCHPAD_PHASE_NOT_FOUND = 11003,
  LAUNCHPAD_PHASE_CONFIG_INVALID = 11004,
  LAUNCHPAD_NOT_MEET_PHASE_RULE = 11005,
  LAUNCHPAD_PHASE_SOLD_OUT = 11006,
  LAUNCHPAD_ENDED = 11007,
  LAUNCHPAD_MINT_AMOUNT_INVALID = 11008,
  PARENT_CONTRACT_NOT_OWNED = 11009,
  PARENT_COLLECTION_NFT_EXPIRED = 11010,
  LAUNCHPAD_ARTICLE_NOT_FOUND = 11011,
  LAUNCHPAD_NOT_SUPPORT_FLEXIBLE = 11012,
  LAUNCHPAD_PHASE_RECORD_NOT_APPROVED = 11013,
  LAUNCHPAD_PHASE_USER_MAX_MINT = 11014,

  /** Receipt Error 12 */
  RECEIPT_NOT_FOUND = 12001,

  /** Balance Error 13 */
  BALANCE_AMOUNT_NOT_ENOUGH = 13001,
  BALANCE_AMOUNT_MAX = 13002,

  /** Remote Error 17 */
  REMOTE_TOKEN_NOT_FOUND = 17001,
  REMOTE_PERMISSION_DENY = 17002,

  /** Webhook Error 18 */
  TRANSACTION_HASH_NOT_FOUND = 18001,
  NFT_ALREADY_MINTED = 18002,
  TREASURY_ADDRESS_NOT_FOUND = 18003,
  NFT_TO_TREASURY = 18004,
  MARKETPLACE_CONTRACT_NOT_FOUND = 18005,
  WEBHOOK_NOT_FOUND = 18006,
  WEBHOOK_TYPE_NOT_SUPPORTED = 18007,

  /** APP API Error 19 */
  TOKEN_NOT_FOUND = 19001,
  NFT_EXPORT_REQUEST_NOT_FOUND = 19002,
  NFT_EXPORT_REQUEST_VERIFY_FAILED = 19003,
  NFT_AUTHORIZED_RECORD_NOT_FOUND = 19004,
  NFT_AUTHORIZED_RECORD_EXPIRED = 19005,

  /** Other Error 99 */
  DATA_NOT_FOUND = 99001,
  TURNSTILE_FAILED = 99002,
  COUNTRY_UNAVAILABLE = 99003,
  TOO_MANY_ATTEMPTS = 99004,
  REQUEST_TIMEOUT = 99005,
  EMAIL_OTP_CODE_INVALID = 99006,
  UNPROCESSABLE_ENTITY = 99007,
  PARAMETER_ERROR = 99008,
  SSO_VERIFY_ACCESS_CODE_ERROR = 99009,
  PERMISSION_DENY = 99010,
  UNKNOWN_ERROR = 99999,
}
